import "../styles/Base.css"

export default function NotFound(){
    
    return (
        <div className="container not-scrollable">
            <h1>oops!</h1>
            <h3>page not found</h3>
        </div>
    )
}