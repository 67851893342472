import "../styles/Base.css"
import box from "../images/box.png"
import "../styles/Home.css"

export default function Home(){
    
    return (
        <div className="container not-scrollable">
            <div className="title">
                <div className="titledesc">
                    <h1>Hello User!</h1>
                    <h2>welcome to boyangsic.com, check out my projects/socials!</h2>
                    <button>&gt; Projects</button>
                </div>
                <img src={box} className="titleimg"></img>
            </div>
        </div>
    )
}