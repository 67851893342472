import "../styles/Base.css"

export default function Success(){
    
    return (
        <div className="container not-scrollable">
            <h1>success!</h1>
            <h3>성공했습니다.</h3>
        </div>
    )
}